import request from '@/request'

export const getProductClassList = (data = {}) => {
    return request({
      url: '/admin/product_class/get_product_class_list',
      method: 'get',
      params: data
    })
}
export const updateProductClass = (data = {}) => {
  return request({
    url: '/admin/product_class/update_product_class',
    method: 'post',
    data
  })
}
export const removeProductClass = (data = {}) => {
  return request({
    url: '/admin/product_class/remove_product_class',
    method: 'post',
    data
  })
}