<template>
    <div class="product">
        <div class="filter flex">
            <a-space>
                <span>Filter</span>
                <a-select
                    ref="select"
                    v-model:value="query.class_id"
                    :options="productClassList"
                    placeholder="Select product class"
                    style="width: 200px"
                    @change="changeProductClass"
                >
                </a-select>
                <a-select
                    ref="select"
                    v-model:value="query.brand_id"
                    :options="brandList"
                    placeholder="Select brand"
                    style="width: 200px"
                >
                </a-select>
                <a-button @click="getProductListHandler">Search</a-button>
                <a-button type="primary" @click="visible = true">
                    <component :is="$antIcons['ShoppingCartOutlined']" />
                    Selected product ({{list.length}})
                </a-button>
                <a-button type="primary" class="primary btn" @click="onClose">Submit</a-button>
            </a-space>
        </div>
        <div class="product_list">
            <a-empty v-if="dataList.length == 0" />
            <a-row :gutter="10" v-else>
                <a-col :span="8" v-for="(product, idx) in dataList" :key="idx">
                    <div class="product_cell flex">
                        <img :src="product.image" class="product_image">
                        <div class="product_info">
                            <div class="product_name">{{product.product_name}}</div>
                            <div class="product_price">${{product.product_price}}</div>
                            <div class="product_bottom flex">
                                <a-input-number style="width:100px" size="small" v-model:value="product.nums" />
                                <a-button size="small" type="primary" @click="selectedProduct(product)" class="primary btn">Add</a-button>
                            </div>
                        </div>
                    </div>
                </a-col>
            </a-row>
        </div>
        <!--产品-->
        <a-drawer
            title="Product"
            placement="bottom"
            height="100%"
            :visible="visible"
            @close="() => visible = false"
            :drawerStyle="{background: '#f9f9f9'}"
        >
            <div class="container">
                <a-table :dataSource="list" :columns="columns" :pagination="false">
                    <template #image="{record}">
                        <img :src="record.image" class="product_image">
                    </template>
                    <template #action="{record, index}">
                        <a-button danger size="small" @click="remove(index)">Delete</a-button>
                    </template>
                </a-table>
            </div>
        </a-drawer>
    </div>
</template>
<script>
import { getBrandList } from '@/apis/brand'
import { getProductClassList } from '@/apis/productClass'
import { getProductList } from '@/apis/product'
const columns = [
    {
        title: 'Product name',
        dataIndex: 'product_name',
        key: 'product_name',
    },
    {
        title: 'Product image',
        slots: { customRender: 'image' },
    },
    {
        title: 'Class name',
        dataIndex: 'class_name',
        key: 'class_name',
    },
    {
        title: 'Brand',
        dataIndex: 'brand_name',
        key: 'brand_name',
    },
    {
        title: 'Product price',
        dataIndex: 'product_price',
        key: 'product_price',
    },
    {
        title: 'Quality',
        dataIndex: 'nums',
        key: 'nums',
    },
    {
        title: 'Action',
        key: 'action',
        slots: { customRender: 'action' },
    },
]
export default {
    name: 'AddProduct',
    props: ['list'],
    data(){
        return {
            query: {
                brand_id: undefined,
                class_id: undefined,
                page: 1
            },
            dataList: [],
            productClassList: [],
            brandList: [],
            visible: false,
            spinning: false,
            columns
        }
    },
    methods: {
        selectedProduct(product){
            const list = this.list
            const index = list.findIndex(r => r.product_id == product.product_id)
            if(index > -1){
                list[index].nums = Number(list[index].nums)+1
            }else{
                list.push(product)
            }
            this.$emit('onProduct', list)
        },
        onClose(){
           this.$emit('onProductClose')
        },
        /**
         * 删除
         */
        remove(index){
            const list = this.list
            list.splice(index, 1)
            this.$emit('onProduct', list)
        },
        /**
         * 切换产品分类
         */
        changeProductClass(class_id){
            this.query.class_id = class_id
            this.getBrandListHandler()
        },
        /**
         * 获取产品分类列表
         */
        async getProductClassListHandler(){
            const res = await getProductClassList()
            if(res){
                this.productClassList = res.list.map(r => {
                    r.label = r.class_name
                    r.value = r.class_id
                    return r
                })
                this.query.class_id = this.productClassList.length == 0 ? undefined : this.productClassList[0].class_id
                console.log(this.query.class_id)
                this.getBrandListHandler()
            }
        },
         /**
         * 获取品牌列表
         */
        async getBrandListHandler(){
            if(this.query.class_id == 'undefined') return
            const res = await getBrandList({class_id: this.query.class_id})
            if(res){
                this.brandList = res.list.map(r => {
                    r.label = r.brand_name
                    r.value = r.brand_id
                    return r
                })
                this.query.brand_id = this.brandList.length == 0 ? undefined :this.brandList[0].brand_id
            }
        },
        /**
         * 获取产品列表
         */
        async getProductListHandler(){
            this.spinning = true
            const res = await getProductList(this.query)
            this.spinning = false
            if(res){
                this.dataList = res.list.rows.map(r => {
                    r.nums = 1
                    return r
                })
                this.total = res.list.count
            }
        },
    },
    created(){
        this.getProductClassListHandler()
    }
}
</script>
<style lang="stylus" scoped>
.filter
    margin-bottom 20px
.product_cell
    margin-top 10px
    padding 10px
    box-shadow 0 10px 10px 0 rgba(0,0,0,.05)
    background #fff
    .product_info
        width 100%
    .product_bottom 
        justify-content space-between
.product_image
    width 80px
</style>