import request from '@/request'

export const upload = (data = {}) => {
    return request({
      url: '/admin/product/upload',
      method: 'post',
      data
    })
}
export const getProductList = (data = {}) => {
  return request({
    url: '/admin/product/get_product_list',
    method: 'get',
    params: data
  })
}
export const updateProduct = (data = {}) => {
return request({
  url: '/admin/product/update_product',
  method: 'post',
  data
})
}
export const removeProduct = (data = {}) => {
return request({
  url: '/admin/product/remove_product',
  method: 'post',
  data
})
}