import request from '@/request'

export const getOrderListForEmployee = (data = {}) => {
    return request({
      url: '/employee/order/get_order_list',
      method: 'get',
      params: data
    })
}

export const updateOrder = (data = {}) => {
  return request({
    url: '/employee/order/update_order',
    method: 'post',
    data
  })
}
