<template>
    <div class="info">
        <div class="order_info">
            <div class="container">
                <div class="form">
                    <div class="form_title">Order detail</div> 
                    <a-row :gutter="10" v-if="orderInfo.source == 0" style="margin-bottom: 20px">
                        <a-col :span="8">
                            <div class="order_box">
                                <strong>{{orderInfo.order_no}}</strong>
                                <span>Order no</span>
                            </div>
                        </a-col>
                        <a-col :span="8">
                            <div class="order_box">
                                <strong>{{getSource(orderInfo.source)}}</strong>
                                <span>Source</span>
                            </div>
                        </a-col>
                        <a-col :span="8">
                            <div class="order_box">
                                <strong>${{orderInfo.detail.bill}}</strong>
                                <span>Bill</span>
                            </div>
                        </a-col>
                        <a-col :span="8">
                            <div class="order_box">
                                <strong>${{orderInfo.cost}}</strong>
                                <span>Cost</span>
                            </div>
                        </a-col>
                        <a-col :span="8">
                            <div class="order_box">
                                <strong>{{orderInfo.detail.recommended.region_name}}</strong>
                                <span>Region</span>
                            </div>
                        </a-col>
                        <a-col :span="8">
                            <div class="order_box">
                                <strong>{{getClassify(orderInfo.detail.recommended.classify)}}</strong>
                                <span>Classify</span>
                            </div>
                        </a-col>
                    </a-row>
                    <a-row :gutter="10" v-if="orderInfo.source == 1" style="margin-bottom: 20px">
                        <a-col :span="8">
                            <div class="order_box">
                                <strong>{{orderInfo.order_no}}</strong>
                                <span>Order no</span>
                            </div>
                        </a-col>
                        <a-col :span="8">
                            <div class="order_box">
                                <strong>{{getSource(orderInfo.source)}}</strong>
                                <span>Source</span>
                            </div>
                        </a-col>
                        <a-col :span="8">
                            <div class="order_box">
                                <strong>${{orderInfo.cost}}</strong>
                                <span>Cost</span>
                            </div>
                        </a-col>
                        <a-col :span="24">
                            <span class="line">Product</span>
                        </a-col>
                        <a-col :span="24" v-show="orderInfo.source == 1" v-for="(product, idx) in orderInfo.detail" :key="idx">
                            <div class="order_box flex">
                                <img :src="product.image" class="image">
                                <div class="product_info">
                                    <span class="product_name">{{product.product_name}}</span>
                                    <span class="product_name">${{product.product_price}}</span>
                                    <span class="product_name">x {{product.nums}}</span>
                                </div>
                            </div>
                        </a-col>
                        <a-col :span="24" style="margin-top: 10px" v-if="getAction(2)">
                             <a-button size="small" @click="openProduct">Modify</a-button>
                        </a-col>
                    </a-row>
                    <a-row :gutter="10" v-if="orderInfo.source == 2" style="margin-bottom: 20px">
                        <a-col :span="8" v-if="orderInfo.action_type == 'edit'">
                            <div class="order_box">
                                <strong>{{orderInfo.order_no || '-'}}</strong>
                                <span>Order no</span>
                            </div>
                        </a-col>
                        <a-col :span="8" v-if="orderInfo.action_type == 'edit'">
                            <div class="order_box">
                                <strong>{{getSource(orderInfo.source)|| '-'}}</strong>
                                <span>Source</span>
                            </div>
                        </a-col>
                        <a-col :span="8" v-if="orderInfo.action_type == 'edit'">
                            <div class="order_box">
                                <strong>{{orderInfo.em.username}}</strong>
                                <span>Employee</span>
                            </div>
                        </a-col>
                        <a-col :span="24">
                            <span class="line">Product</span>
                        </a-col>
                        <a-col :span="24" v-show="orderInfo.source == 2" v-for="(product, idx) in orderInfo.detail" :key="idx">
                            <div class="order_box flex">
                                <img :src="product.image" class="image">
                                <div class="product_info">
                                    <span class="product_name">{{product.product_name}}</span>
                                    <span class="product_name">${{product.product_price}}</span>
                                    <span class="product_name">x {{product.nums}}</span>
                                </div>
                            </div>
                        </a-col>
                        <a-col :span="24" style="margin-top: 10px" v-if="getAction(2)">
                             <a-button size="small" @click="openProduct">Modify</a-button>
                        </a-col>
                    </a-row>
                </div>
            </div>
            <div class="container">
                <a-spin :spinning="spinning">
                <div class="form">
                    <div class="form_title">order detail</div>
                    <a-form :model="orderInfo" :label-col="labelCol" :wrapper-col="wrapperCol">
                        <a-form-item label="Name">
                            <a-input v-model:value="orderInfo.name" placeholder="Name" />
                        </a-form-item>
                        <a-form-item label="Email">
                            <a-input v-model:value="orderInfo.email" placeholder="Email" />
                        </a-form-item>
                        <a-form-item label="Phone number">
                            <a-input v-model:value="orderInfo.phone" placeholder="Phone" />
                        </a-form-item>
                        <a-form-item label="Address">
                            <a-input v-model:value="orderInfo.address" placeholder="Address"  />
                        </a-form-item>
                        <a-form-item label="Proposed installation date">
                            <a-date-picker valueFormat="YYYY-MM-DD" :showToday="false" v-model:value="orderInfo.instation_date" style="width: 100%" placeholder="Proposed installation date" />
                        </a-form-item>
                        <a-form-item label="Already have a solar system">
                            <a-switch v-model:checked="orderInfo.have_system" />
                        </a-form-item>
						<a-form-item label="design plan:">
                            <img v-if="orderInfo.orderStreams" v-for="(dp,index) in orderInfo.orderStreams.design_plan" v-bind:src="dp.name" class="simage">
						</a-form-item>
						<a-form-item label="installation:">
                            <img v-if="orderInfo.orderStreams" v-for="(ins,index) in orderInfo.orderStreams.installation" v-bind:src="ins.name" class="simage">
						</a-form-item>
						<a-form-item label="contract">
                            <img v-if="orderInfo.orderStreams" v-for="(cont,index) in orderInfo.orderStreams.contract" v-bind:src="cont.name" class="simage">
						</a-form-item>
                        <a-form-item v-if="getAction(2)" :wrapper-col="{ span: 14, offset: 10 }">
                            <a-space>
                                <a-button type="primary" @click="updateOrderHandler">Submit</a-button>
                            </a-space>
                        </a-form-item>
                    </a-form>
                </div>
                </a-spin>
            </div>
            <!--修改产品-->
            <a-drawer
                title="Product"
                placement="bottom"
                height="100%"
                :visible="productVisible"
                @close="() => productVisible = false"
                :drawerStyle="{background: '#f9f9f9'}"
            >
                <div class="container">
                    <a-spin :spinning="spinning">
                        <AddProduct @onProductClose="onProductClose" @onProduct="onProduct" :list="orderInfo.detail || []"/>
                    </a-spin>
                </div>
            </a-drawer>
        </div>
    </div>
</template>
<script>
import { message } from 'ant-design-vue'
import { updateOrder } from '@/apis/employee/order'
import AddProduct from '@/components/product/addProduct.vue'
import commonMixins from '@/mixins/commonMixins'
import { interesteList  } from '@/config/const'
export default {
    name: 'OrderInfo',
    props: ['orderInfo'],
    mixins: [commonMixins],
    components: {
        AddProduct
    },
    data(){
        return {
            labelCol: { span: 10 },
            wrapperCol: { span: 14 },
            spinning: false,
            productVisible: false,
            spinning: false
        }
    },
    methods: {
        onProductClose(){
            this.productVisible = false
        },
        /**
         * 打开产品弹窗
         */
        openProduct(){
            this.productVisible = true
        },
        /**
         * 产品回调
         */
        onProduct(products){
            this.orderInfo.detail = products
        },
        /**
         * 删除产品
         */
        removeProduct(index){
            this.orderInfo.detail.splice(index ,1)
        },
        async updateOrderHandler(){
            this.spinning = true
            const res = await updateOrder(this.orderInfo)
			console.log(res)
            this.spinning = false
            if(res){
                message.success('success')
                this.$emit('onRefresh')
            }
        }
    },
    computed: {
        getSource(){
            return source => {
                switch(source){
                    case '0' :
                        return 'Recommended'
                    case '1' :
                        return 'Diy store'
                    case '2' :
                        return 'Employee'
                }
            }
        },
        getClassify(){
            return classify => {
                return interesteList.filter(r => r.id == classify)[0].name
            }
        }
    },
}
</script>
<style lang="stylus" scoped>
.simage
    width 200px
.order_box
    margin-bottom 10px
.order_box span
.order_box strong
    display block
    text-align center
.order_box strong
    font-size 16px
.order_box span
    color #abb1bf
.order_box .image
    width 60px
.product_name
    color #000 !important
    text-align left !important
.line
    text-align center
    display block
    line-height 40px
    border-top 1px solid #eee
    font-size 18px
</style>