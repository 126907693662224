import request from '@/request'

export const getBrandList = (data = {}) => {
    return request({
      url: '/admin/brand/get_brand_list',
      method: 'get',
      params: data
    })
}
export const updateBrand = (data = {}) => {
  return request({
    url: '/admin/brand/update_brand',
    method: 'post',
    data
  })
}
export const removeBrand = (data = {}) => {
  return request({
    url: '/admin/brand/remove_brand',
    method: 'post',
    data
  })
}